import React from "react";
import { graphql } from "gatsby";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AbleSenseHero from "../components/able-sense-page/able-sense-hero";
import RichTextContent from "../components/general/rich-text-content";

const ContentPage = ({ data }) => {
  const { contentPage } = data.takeshape;

  if (
    (!contentPage.enabled && process.env.GATSBY_ENVIRONMENT !== "staging") ||
    (!contentPage.enableOnStaging &&
      process.env.GATSBY_ENVIRONMENT === "staging")
  ) {
    if (typeof window !== "undefined") {
      navigate("/");
    }
  }

  return (
    <Layout>
      <Seo
        title={contentPage.pageTitle}
        description={contentPage.pageDescription}
      />
      <div className="main--wrap">
        <AbleSenseHero
          title={contentPage.title}
          heroDesktopImage={contentPage?.heroDesktopImage}
          heroMobileImage={contentPage?.heroMobileImage}
        />
        <RichTextContent content={contentPage.content} />
      </div>
    </Layout>
  );
};

export default ContentPage;

export const query = graphql`
  query($id: ID!) {
    takeshape {
      contentPage: getContentPage(_id: $id) {
        content(format: html)
        enableOnStaging
        enabled
        heroDesktopImage {
          caption
          path
          sourceUrl
          title
          metadata {
            PixelWidth
            PixelHeight
          }
        }
        heroMobileImage {
          caption
          path
          sourceUrl
          title
          metadata {
            PixelWidth
            PixelHeight
          }
        }
        pageTitle
        pageDescription
      }
    }
  }
`;
