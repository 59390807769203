import React, { useEffect, useState } from "react";
import LazyImage from "../lazyImage";
import { getImageUrl } from "takeshape-routing";

const AbleSenseHero = ({ heroDesktopImage, heroMobileImage }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="section able-sense-hero"
      style={{
        paddingBottom:
          windowSize.width < 768
            ? `${
                (heroMobileImage?.metadata?.PixelHeight /
                  heroMobileImage?.metadata?.PixelWidth) *
                100
              }%`
            : `${
                (heroDesktopImage?.metadata?.PixelHeight /
                  heroDesktopImage?.metadata?.PixelWidth) *
                100
              }%`,
      }}
    >
      <LazyImage
        src={getImageUrl(heroMobileImage ?? heroDesktopImage, {
          fit: "crop",
          auto: "compress",
        })}
        className="able-sense-hero__image able-sense-hero__image--mobile"
      ></LazyImage>
      {heroDesktopImage && (
        <LazyImage
          src={getImageUrl(heroDesktopImage, {
            fit: "crop",
            auto: "compress",
          })}
          className="able-sense-hero__image"
        ></LazyImage>
      )}
      <div className="container container--bordered container--overlay able-sense-hero__grid" />
    </div>
  );
};

export default AbleSenseHero;
